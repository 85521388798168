import React from 'react';
import { Link } from 'react-router-dom';

export default class FooterOne extends React.Component {
  render() {
    let publicUrl = '/';
    return (
      <>
        <footer className='footer-one-sec'>
          <div
            className='footer-one__pattern'
            style={{
              backgroundImage:
                'url(' +
                publicUrl +
                'assets/images/pattern/footer-v1-pattern.png)',
            }}
          ></div>
          <div className='footer-one__top'>
            <div
              className='footer-one__top-img'
              // style={{
              //   backgroundImage:
              //     'url(' + publicUrl + 'assets/images/resources/ansice.png)',
              // }}
            ></div>
            <div className='container'>
              <div className='row'>
                <div className='col-xl-12'>
                  <div className='footer-one__top-inner'>
                    <div className='row'>
                      <div
                        className='col-xl-4 col-lg-6 col-md-4 wow animated fadeInUp'
                        data-wow-delay='0.1s'
                      >
                        <div className='footer-widget__column footer-widget__about'>
                          <div className='footer-widget__about-logo'>
                            {/* <Link to='/'>
                              <img
                                src={
                                  publicUrl +
                                  'assets/images/resources/ansice.png'
                                }
                                width={100}
                                height={90}
                                alt='Logo'
                              />
                            </Link> */}
                            <h2 className='footer-widget__title'>
                              Nos Coordonnées
                            </h2>
                          </div>

                          <ul className='footer-widget__about-text'>
                            <li>Tél: +235 97 31 31 90 | 67 31 31 90</li>
                            <li>E-Mail : contact@ansice.td</li>
                            <li>
                              Adresse : N’djaména, Quartier Klémat, Avenue
                              Joseph Brahim-Seïd, Porte 507, BP:5216.
                            </li>{' '}
                          </ul>
                          <div className='footer-widget__about-social-link'>
                            <ul>
                              <li>
                                <a
                                  href='https://www.facebook.com/p/ANSICE-TCHAD-100059938052202/'
                                  target='_blank'
                                >
                                  <span className='first icon-facebook-app-symbol'></span>
                                  <span className='second icon-facebook-app-symbol'></span>
                                </a>
                              </li>

                              <li>
                                <a href='#'>
                                  <span className='first icon-twitter'></span>
                                  <span className='second icon-twitter'></span>
                                </a>
                              </li>

                              <li>
                                <a
                                  href='https://td.linkedin.com/company/ansice'
                                  target='_blank'
                                >
                                  <span className='first icon-linkedin'></span>
                                  <span className='second icon-linkedin'></span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div
                        className='col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp'
                        data-wow-delay='0.3s'
                      >
                        <div className='footer-widget__column footer-widget__services'>
                          <h2 className='footer-widget__title'>Liens utiles</h2>
                          <ul className='footer-widget__services-list'>
                            <li className='footer-widget__services-list-item'>
                              <a href='https://presidence.td' target='_blank'>
                                Présidence de la République du Tchad
                              </a>
                            </li>

                            <li className='footer-widget__services-list-item'>
                              <a href='http://enastic.td' target='_blank'>
                                ENASTIC
                              </a>
                            </li>
                            <li className='footer-widget__services-list-item'>
                              <a href=' https://arcep.td' target='_blank'>
                                ARCEP
                              </a>
                            </li>

                            <li className='footer-widget__services-list-item'>
                              <a href='https://adetic.td' target='_blank'>
                                ADETIC
                              </a>
                            </li>

                            {/* <li className='footer-widget__services-list-item'>
                              <Link to='/'>Our Case Study</Link>
                            </li>
                            */}
                          </ul>
                        </div>
                      </div>

                      <div
                        className='col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp'
                        data-wow-delay='0.5s'
                      >
                        <div className='footer-widget__column footer-widget__explore'>
                          <h2 className='footer-widget__title'>Menu</h2>
                          <ul className='footer-widget__explore-list'>
                            <li className='footer-widget__explore-list-item'>
                              <Link to='/publications'>Réglementation</Link>
                            </li>
                            <li className='footer-widget__explore-list-item'>
                              <Link to='/alert'>Alertes</Link>
                            </li>
                            <li className='footer-widget__explore-list-item'>
                              <Link to='/actualites'>Actualités </Link>
                            </li>
                            <li className='footer-widget__explore-list-item'>
                              <Link to='/goodPractices'>Bonnes Pratiques</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='footer-one__bottom clearfix'>
            <div className='container'>
              <div className='row'>
                <div className='col-xl-12'>
                  <div className='footer-one__bottom-inner'>
                    <div className='footer-one__bottom-text'>
                      <p>
                        Copyright &copy;2024. All rights reserved to{' '}
                        <Link to='/'>ANSICE</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
