import React from "react";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import NewsPage from "./pages/NewsPage";
import OrgChartPage from "./pages/OrgChartPage";
import NewsDetailPage from "./pages/NewsDetailsPage";
import MissionPage from "./pages/MissionPage";
import DocumentsPage from "./pages/documents";
import DirecteurGeneralPage from "./pages/DirecteurGeneralPage";
import AlertsPage from "./pages/Alerts";
// import RÉGLEMENTATION from './pages/Publications';
import CommuniquePage from "./pages/communique/CommuniquePage";
import BrandOnePage from "./components/brand/BrandPage";
import GoodPracticesPage from "./pages/GoodPractices/goodPractices";
import ReportAlertPage from "./pages/ReportAlert";
import CyberIncidentReportingPage from "./pages/IncidentPage";
const routes = [
  {
    path: "/",
    component: <HomePage />,
    name: "Home",
  },
  {
    path: "/actualites",
    component: <NewsPage />,
    name: "Actualites",
  },
  {
    path: "/actualites/:id",
    component: <NewsDetailPage />,
    name: "Actualites",
  },
  {
    path: "/organigramme",
    component: <OrgChartPage />,
    name: "Organigramme",
  },
  {
    path: "/publications",
    component: <DocumentsPage />,
    name: "Publications",
  },

  {
    path: "/mission/:id?",
    component: <MissionPage />,
    name: "Mission",
  },
  {
    path: "/nos-partenaires",
    component: <BrandOnePage />,
    name: "nos-partenaires",
  },
  {
    path: "/directeur-general",
    component: <DirecteurGeneralPage />,
    name: "Contact",
  },
  {
    path: "/goodPractices",
    component: <GoodPracticesPage />,
    name: "goodPractices",
  },

  {
    path: "/cyber-incident",
    // component: <CyberIncidentReportingPage />,
    component: <ReportAlertPage />,
    name: "cyber-incident",
  },
  {
    path: "/cyber-incident/:formType",
    // component: <CyberIncidentReportingPage />,
    component: <CyberIncidentReportingPage />,
    name: "CyberIncidentReportingPage",
  },

  {
    path: "/contact",
    component: <ContactPage />,
    name: "Contact",
  },
  {
    path: "/communique",
    component: <CommuniquePage />,
    name: "communique",
  },
  {
    path: "/alert",
    component: <AlertsPage />,
    name: "alert",
  },
];

export default routes;
