import React, { useEffect, useState } from 'react';
import MainLayout from '../../layout/MainLayout';
import { Link } from 'react-router-dom';
import axios from '../../common/utils/axios';
import config from '../../common/config/env';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';

import './style.css';
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
const NewsPage = () => {
  const [loading, setLoading] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 2,
  });
  const [pageNo, setPageNo] = useState(1);

  const fetchNews = async (filter = {}) => {
    setLoading(true);
    const response = await axios.get('newss?populate=*', {});
    setNewsList(response.data);
    setPagination(response?.data?.meta?.pagination);
    setLoading(false);
  };

  useEffect(() => {
    fetchNews();
  }, [pageNo]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow`}
        style={{
          ...style,
          display: 'block',
          background: 'blue',
          borderRadius: '50%',
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow`}
        style={{
          ...style,
          display: 'block',
          background: 'blue',
          borderRadius: '50%',
        }}
        onClick={onClick}
      />
    );
  }

  const newsFeeds = newsList?.data?.sort((a, b) => {
    const dateA = moment(a.attributes.newsDate, 'DD/MM/YYYY');
    const dateB = moment(b.attributes.newsDate, 'DD/MM/YYYY');

    return dateB.diff(dateA);
  });

  console.log(newsFeeds);

  return (
    <MainLayout>
      <section className=''>
        <LoadingOverlayWrapper active={loading} spinner>
          <div className='p-5'>
            <div className='sec-title text-center'>
              <div className='sec-title__tagline'>
                <span className='left'></span>
                <h6>Actualites</h6> <span className='right'></span>
              </div>
              <h2 className='sec-title__title mb-5'> Actualites Recents</h2>
            </div>
            <Slider {...settings}>
              {newsFeeds?.map((news, index) => (
                <div
                  key={index}
                  className='col-xl-3 col-lg-3 wow animated fadeInUp'
                  data-wow-delay={`0.${index + 2}s`}
                >
                  <div className='blog-one__single'>
                    <div className='blog-one__single-img'>
                      <div className='inner'>
                        <img
                          src={
                            config.HOST_URL +
                            news?.attributes?.CoverImage?.data?.attributes?.url
                          }
                          height={400}
                          width={300}
                          alt=''
                        />
                      </div>
                      <div className='blog-one__single-content'>
                        <div className='inner-content'>
                          <ul className='meta-info'>
                            <li>
                              <span className='icon-hotel'></span>
                              <Link to={`/actualites/${news?.id}`}>
                                {news?.attributes?.place}
                              </Link>
                            </li>
                            <li>
                              <span className='icon-maps-and-flags'></span>
                              <Link to={`/actualites/${news?.id}`}>
                                {news?.attributes?.newsDate}
                              </Link>
                            </li>
                          </ul>
                          <p>
                            <Link to={`/actualites/${news?.id}`}>
                              {news?.attributes?.title}
                            </Link>
                          </p>

                          <div className='row'>
                            <div className='btn-box col-md-8'>
                              <Link to={`/actualites/${news?.id}`}>
                                Lire Plus{' '}
                                <span className='icon-right-arrow-1'></span>
                              </Link>
                            </div>
                            <div className='share-btns  btn-box col-md-4'>
                              <FacebookShareButton
                                // url={`http://localhost:3000/actualites/${news?.id}`}

                                url={`${window.location.origin}/actualites/${news?.id}`}
                                // url={`${window.location.origin}actualites/${news?.id}`}
                                // {window.location.origin}/$
                                // url={`${config.HOST_URL}${actualites/${news?.id}`}
                                quote={news?.attributes?.title}
                                image={`${config.HOST_URL}${news?.attributes?.CoverImage?.data?.attributes?.url}`}
                              >
                                <FacebookIcon
                                  className='m-1'
                                  size={20}
                                  round={true}
                                />
                              </FacebookShareButton>
                              <LinkedinShareButton
                                url={`${window.location.origin}/actualites/${news?.id}`}
                                title={news?.attributes?.title}
                                image={`${config.HOST_URL}${news?.attributes?.CoverImage?.data?.attributes?.url}`}
                              >
                                {console.log(LinkedinShareButton.url)}
                                <LinkedinIcon
                                  className='m-1'
                                  size={20}
                                  round={true}
                                />
                              </LinkedinShareButton>
                              <WhatsappShareButton
                                url={`${window.location.origin}/actualites/${news?.id}`}
                                title={news?.attributes?.title}
                                image={`${config.HOST_URL}${news?.attributes?.CoverImage?.data?.attributes?.url}`}
                              >
                                <WhatsappIcon
                                  className='m-1'
                                  size={20}
                                  round={true}
                                />
                              </WhatsappShareButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </LoadingOverlayWrapper>
      </section>
    </MainLayout>
  );
};

export default NewsPage;
