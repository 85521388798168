import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import axiosInstance from '../../common/utils/axios';
import { Button, Form } from 'react-bootstrap';
import { showMessage } from '../../Utility';

export default function ContactForm() {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const handleFormSubmit = (payload) => {
    setLoading(true);
    axiosInstance
      .post('send-email', {
        data: {
          ...payload,
          type: 'contact_form',
          subject: 'Formulaire de contact',
        },
      })
      .then((resp) => {
        setLoading(false);
        if (resp?.data?.success) {
          // add notification
          showMessage('Merci pour votre message', 'success');
          reset();
        }
      })
      .catch((err) => {
        setLoading(false);
        showMessage('Une erreur est survenue', 'error');
        console.log('entreprise-cyber-incidents post error', err);
      });
  };

  return (
    <>
      <div className='contact-page'>
        <LoadingOverlayWrapper active={loading} spinner>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-5'>
                <div className='contact-page__content '>
                  <div className='title'>
                    <h2 className='mb-2'>&nbsp;</h2>
                  </div>

                  <div className='contact-page__content-single'>
                    <div className='contact-page__content-single-inner'>
                      <div className='icon-box'>
                        <span className='icon-pin'></span>
                      </div>

                      <div className='content-box'>
                        <h2>adresse</h2>
                        <p>
                          N’djaména, Quartier Klémat, Avenue Joseph Brahim-Seïd,
                          <br /> Porte 507, BP:5216.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='contact-page__content-single'>
                    <div className='contact-page__content-single-inner'>
                      <div className='icon-box'>
                        <span className='icon-phone-call-1'></span>
                      </div>

                      <div className='content-box'>
                        <h2>Telephone</h2>

                        <p className='number2'>
                          <a href='tel:+23567313190'>
                            +235 97 31 31 90 | 67 31 31 90
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='contact-page__content-single'>
                    <div className='contact-page__content-single-inner'>
                      <div className='icon-box'>
                        <span className='icon-email'></span>
                      </div>

                      <div className='content-box'>
                        <h2>Email </h2>
                        {/* <p className='email1'>
                        <a href='mailto:info@madina.com'>info@ansice.td</a>
                      </p> */}
                        <p className='email2'>
                          <a href='mailto:info@madina.com'>contact@ansice.td</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-xl-8 col-lg-8 col-md-7'>
                <div className='contact-page__form'>
                  <Form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className='row'>
                      <div className='col-xl-6 col-lg-6'>
                        <div className='comment-form__input-box mb-3'>
                          <Form.Group controlId='full_name'>
                            <Form.Control
                              {...register('full_name', { required: true })}
                              placeholder='Full Name'
                              className={
                                errors['full_name']
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className='col-xl-6 col-lg-6'>
                        <div className='comment-form__input-box'>
                          <Form.Group controlId='email'>
                            <Form.Control
                              {...register('email', { required: true })}
                              type='email'
                              placeholder='Email Address'
                              className={
                                errors['email']
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-xl-6 col-lg-6'>
                        <div className='comment-form__input-box'>
                          <Form.Group controlId='telephone'>
                            <Form.Label></Form.Label>
                            <Form.Control
                              {...register('telephone', { required: true })}
                              placeholder='phone Number'
                              className={
                                errors['telephone']
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className='col-xl-6 col-lg-6'>
                        <div className='comment-form__input-box'>
                          <Form.Group controlId='subject_text'>
                            <Form.Label></Form.Label>
                            <Form.Control
                              {...register('subject_text', {
                                required: true,
                                placeholder: 'subject_text',
                              })}
                              placeholder='Subject'
                              className={
                                errors['subject_text']
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-xl-12 col-lg-12'>
                        <div className='comment-form__input-box text-message-box'>
                          <Form.Group controlId='message'>
                            <Form.Label></Form.Label>
                            <Form.Control
                              {...register('message', {
                                required: true,
                                placeholder: 'message',
                              })}
                              placeholder='message'
                              as='textarea'
                              rows='6'
                              className={
                                errors['message']
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className='col-xl-12 col-lg-12 mt-3'>
                        <Button
                          className='thm-btns comment-form__btn btn btn-success'
                          type='submit'
                        >
                          Envoyer Message
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlayWrapper>
      </div>
    </>
  );
}
