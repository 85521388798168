import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axiosInstance from "../../common/utils/axios";
import FileUpload from "../../components/fileupload/FileUpload";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import config from "../../common/config/env";
import { useForm } from "react-hook-form";
import { showMessage } from "../../Utility";
export default function RecrutementModal({ show, handleClose }) {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});

  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const handleInputValueChange = (value, key) => {
    setPayload((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleFormSubmit1 = () => {
    axiosInstance
      .post("recruitment-forms", {
        data: payload,
      })
      .then((resp) => {})
      .catch((err) => {
        console.log("Recruitment-forms post error", err);
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleFormSubmit = () => {
    setLoading(true);
    axiosInstance
      .post("send-email", {
        data: {
          ...payload,
          type: "recrutement",
          subject: "Formulaire de Recrutement",
        },
      })
      .then((resp) => {
        setLoading(false);
        if (resp?.data?.success) {
          showMessage("Votre message a bien été envoyé", "success");
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
        showMessage("Une erreur est survenue", "error");
        console.log("internship-forms post error", err);
      });
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <LoadingOverlayWrapper active={loading} spinner>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              Formulaire de Recrutement
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-4">
            <div className="row">
              <div className="row col-md-6">
                <Form.Control
                  {...register("name", { required: true })}
                  placeholder="Nom"
                  className={
                    errors["name"]
                      ? "mt-3 form-control is-invalid"
                      : "mt-3 form-control"
                  }
                />
                <Form.Control
                  {...register("surname", { required: true })}
                  placeholder="Prenom"
                  className={
                    errors["surname"]
                      ? "mt-3 form-control is-invalid"
                      : "mt-3 form-control"
                  }
                />
                <Form.Control
                  {...register("email", { required: true })}
                  placeholder="email"
                  className={
                    errors["email"]
                      ? "mt-3 form-control is-invalid"
                      : "mt-3 form-control"
                  }
                />
                <Form.Control
                  {...register("telephone", { required: true })}
                  placeholder="Telephone"
                  className={
                    errors["telephone"]
                      ? "mt-3 form-control is-invalid"
                      : "mt-3 form-control"
                  }
                  type="number"
                />
              </div>

              <div className="col-md-6">
                <Form.Select
                  {...register("degree", { required: true })}
                  className={
                    errors["degree"]
                      ? "mt-3 form-control is-invalid"
                      : "mt-3 form-control"
                  }
                >
                  <option>choisissez votre Diplôme</option>
                  <option value="PhD">PhD</option>
                  <option value="Master_II">Master II</option>

                  <option value="Master_I">Master I</option>
                  <option value="License">License</option>
                  <option value="BAC">BAC</option>
                </Form.Select>

                <Form.Group
                  className="mt-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  {/* <Form.Label>Explain your interest</Form.Label> */}
                  <Form.Control
                    {...register("interest", { required: true })}
                    className={
                      errors["interest"]
                        ? "mt-3 form-control is-invalid"
                        : "mt-3 form-control"
                    }
                    placeholder="Expliquez vos intérêts"
                    rows={8}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <FileUpload
                  allowedFiles={[".pdf"]}
                  label="Demande manuscrite"
                  isMultiple={false}
                  isServerUpload={true}
                  onUploadFinish={(fileurl, filepath) => {
                    clearErrors("cv");
                    setValue("cv", config?.HOST_URL + filepath?.url);
                  }}
                  filepath={payload.cv}
                />
              </div>
              <div className="col-md-6">
                <FileUpload
                  allowedFiles={[".pdf"]}
                  label="télécharger votre CV"
                  isMultiple={false}
                  isServerUpload={true}
                  onUploadFinish={(fileurl, filepath) => {
                    clearErrors("coverletter");
                    setValue("coverletter", config?.HOST_URL + filepath?.url);
                  }}
                  filepath={payload.coverletter}
                />
              </div>

              {/* <FileUpload
            allowedFiles={FILE_FORMATS}
            isMultiple={false}
            isServerUpload={false}
            onUploadFinish={(fileurl, filepath) => {}}
            filepath={filepath}
          /> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Enregistrer
            </Button>
          </Modal.Footer>
        </Form>
      </LoadingOverlayWrapper>
    </Modal>
  );
}
