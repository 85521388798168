import React from 'react';
import { Link } from 'react-router-dom';
import CEO_DATA from '../../data/ceo.json';

const Paragraph = ({ children, isNewPara }) =>
  isNewPara ? (
    <p style={{ textAlign: 'justify' }}>{children}</p>
  ) : (
    <div style={{ textAlign: 'justify' }}>{children}</div>
  );

const replaceBoldTags = (text) => {
  return text
    .split('<b/>')
    .map((part, index) =>
      index % 2 === 1 ? <strong key={index}>{part}</strong> : part
    );
};

export default class DirectionInfo extends React.Component {
  componentDidMount() {}

  render() {
    let publicUrl = '/';
    const { fullDetail } = this.props;
    let message = CEO_DATA.message;
    if (!fullDetail) {
      message = CEO_DATA.message?.slice(0, 1050);
    }
    console.log(message);
    return (
      <>
        <section className='feature-three-sec clearfix'>
          <div
            className='feature-three__bg'
            style={{
              backgroundImage: 'url(' + publicUrl + CEO_DATA.photo + ')',
            }}
          ></div>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='feature-three__title'>
                  <div className='sec-title'>
                    <div className='sec-title__tagline'>
                      <span className='right'></span>{' '}
                      <h6 className='ml-4'>
                        &nbsp;&nbsp;&nbsp;MOT DU DIRECTEUR GÉNÉRAL
                      </h6>{' '}
                      <span className='right'></span>
                    </div>
                    <h2 className='sec-title__title'>
                      {CEO_DATA.name} <br />
                    </h2>
                    {/* <p className='sec-title__text'>{CEO_DATA.title}</p> */}
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-11 col-xs-11'>
                <div className='feature-three__tab tabs-box'>
                  <div className='tabs-content'>
                    {/* Start Tab */}
                    <div className='tab active-tab' id='history'>
                      <div className='feature-three__tab-content-item'>
                        {/* Start Feature Three History Single */}
                        <div className='feature-three__history-single'>
                          {message?.split('<br/>')?.map((item, index) => {
                            return (
                              <Paragraph
                                className='datseS-box'
                                isNewPara={index !== 0}
                                key={index}
                              >
                                {replaceBoldTags(item)}
                                {!fullDetail &&
                                message?.split('<br/>')?.length ===
                                  index + 1 ? (
                                  <>
                                    ...
                                    <Link to='/directeur-general'>
                                      Lire Plus
                                    </Link>
                                  </>
                                ) : (
                                  ''
                                )}
                              </Paragraph>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {/* End Tab */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
