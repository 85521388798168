import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import axios from "../../common/utils/axios";
import config from "../../common/config/env";
import { useParams } from "react-router-dom";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
export default function NewsDetailPage() {
  const params = useParams();
  const [newsData, setNewsData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchNews = async () => {
    setLoading(true);
    const response = await axios.get(`newss/${params?.id}?populate=*`);
    setNewsData(response.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <MainLayout>
      <section className="">
        <div className="p-3 p-lg-5">
          <div className="sec-title text-center">
            <div className="sec-title__tagline">
              <span className="left"></span>
              <h6>Actualites</h6>
              <span className="right"></span>
            </div>
            <h3 className="sec-title__title mb-3">
              {newsData?.attributes?.title}
            </h3>

            <div className="row">
              <div className="col-md-4">
                <div>
                  <h5>Date : {newsData?.attributes?.newsDate}</h5>
                  <h5>Lieu : {newsData?.attributes?.place}</h5>
                </div>
              </div>
            </div>
          </div>

          <LoadingOverlayWrapper active={loading} spinner>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-10">
                  <div className="blog-details__left">
                    <div className="blog-details__content pb-5">
                      {newsData?.attributes?.description?.map((item, index) => {
                        switch (item?.type) {
                          case "paragraph":
                            return (
                              <p
                                className="blog-details__text-1"
                                style={{ textAlign: "justify" }} // Justify the text
                                key={index}
                              >
                                {item?.children?.map((childrenItem, idx) => {
                                  return (
                                    <span
                                      style={{
                                        fontWeight: childrenItem?.bold
                                          ? "bold"
                                          : 400,
                                      }}
                                      key={idx}
                                    >
                                      {childrenItem?.text}
                                    </span>
                                  );
                                })}
                              </p>
                            );

                          default:
                            return null;
                        }
                      })}
                    </div>
                    <div className="blog-details__img text-center">
                      <img
                        className="img-fluid" // Make the image responsive
                        style={{ maxWidth: "100%", height: "auto" }} // Ensure the image does not overflow
                        src={
                          config?.HOST_URL +
                          newsData?.attributes?.CoverImage?.data?.attributes
                            ?.url
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LoadingOverlayWrapper>
        </div>
      </section>
    </MainLayout>
  );
}
