import React from 'react';
import LogoWhite from './LogoWhite';
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import NiceSelect from './NiceSelect';

const HeaderThree = () => {
  return (
    <>
      <header className='main-header main-header-one navbar fixed-top  clearfix'>
        <div className='main-header-one__wrapper'>
          <div className='main-header-one__top'>
            <div className='container-fluid'>
              <div className='main-header-one__top-inner'>
                <div className='main-header-one__left'>
                  <LogoWhite /> &nbsp; <h1>ANSICE</h1>
                </div>

                <div className='main-header-one__middle'>
                  <ul>
                    <li>
                      <div className='text'>
                        <h5 className='lh-sm  text-warning text-center'>
                          AGENCE NATIONALE DE SÉCURITÉ INFORMATIQUE ET DE
                          CERTIFICATION ELECTRONIQUE
                        </h5>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className='main-header-one__right'>
                  {/* <OffCanvasStyleOne /> */}
                </div>
              </div>
            </div>
          </div>

          <div className='main-header-one__bottom'>
            <div className='container-fluid'>
              <div className='main-header-one__bottom-inner'>
                <div className='main-header-one__bottom-left'>
                  <nav className='main-menu main-menu--1'>
                    <div className='main-menu__inner'>
                      <MobileMenu />
                      <div className='stricky-one-logo'>
                        <LogoWhite />
                      </div>
                      <Nav />
                    </div>
                  </nav>
                </div>

                <div className='  main-header-lang'>
                  <div className='cart-btn main-header-lang'>
                    <NiceSelect />
                  </div>

                  <div className='main-header-one__bottom-right-btn'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderThree;
