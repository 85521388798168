import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import MainLayout from '../../layout/MainLayout';
// import './style.css';
const data = {
  Communique: [
    {
      title:
        "L’adhésion de l'ANSICE au secteur de développement de l’Union Internationale des Télécommunications",
      link: '../../assets/documents/Communiqué_4.jpg',
    },
    {
      title:
        'Campagne de désinformation visant les citoyens tchadiens en cette période cruciale des élections présidentielles',
      link: '../../assets/documents/Communiqué_1.pdf',
    },

    {
      title:
        'Communiqué de presse sur une panne technique des applications des réseaux sociaux',
      link: '../../assets/documents/Communiqué_3.pdf',
    },

    {
      title:
        "Communiqué de presse sur l'attaque cybernétique de l'hotel Radisson blu",
      link: '../../assets/documents/Communiqué_2.pdf',
    },
  ],
};

const CommuniquePage = () => {
  useEffect(() => {
    const $ = window.$;

    if ($('.tabs-box').length) {
      $('.tabs-box .tab-buttons .tab-btn').on('click', function (e) {
        e.preventDefault();
        const target = $($(this).attr('data-tab'));

        if ($(target).is(':visible')) {
          return false;
        } else {
          target
            .parents('.tabs-box')
            .find('.tab-buttons')
            .find('.tab-btn')
            .removeClass('active-btn');
          $(this).addClass('active-btn');
          target
            .parents('.tabs-box')
            .find('.tabs-content')
            .find('.tab')
            .fadeOut(0);
          target
            .parents('.tabs-box')
            .find('.tabs-content')
            .find('.tab')
            .removeClass('active-tab');
          $(target).fadeIn(300);
          $(target).addClass('active-tab');
        }
      });
    }
  }, []);

  const renderTabContent = (category) => (
    <Row>
      {data[category].map((item, index) => (
        <Col md={12} key={index} className='m-1'>
          <ListGroup
            as='ul'
            style={{ listStyleType: 'disc', paddingLeft: '20px' }}
          >
            <ListGroup.Item className='icon'>
              <span className=' icon-tick'></span>{' '}
              <span className=' ml-3'>
                {' '}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.title}{' '}
              </span>
              <a href={item.link} target='_blank' className='text-primary'>
                Télécharger
                <span className='icon-document '></span>
              </a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      ))}
    </Row>
  );

  return (
    <MainLayout>
      <section className='feature-three-sec clearfix'>
        <div className='container'>
          {/* <div className='sec-title text-center'>
            <div className='sec-title__tagline'>
              <span className='left'></span>
              <h6>Communiques</h6> <span className='right'></span>
            </div>
          </div> */}
          <div className='row'>
            <div className='col-xl-12'>
              <div className='feature-three__tab tabs-box'>
                <div className='feature-three__tab-button'>
                  <ul className='tab-buttons clearfix'>
                    <li data-tab='#lois' className='tab-btn active-btn'>
                      <h3> Communiques</h3>
                    </li>
                  </ul>
                </div>

                <div className='tabs-content'>
                  <div className='tab active-tab' id='Communique'>
                    {renderTabContent('Communique')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default CommuniquePage;
