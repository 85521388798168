import React from 'react';
import { Link } from 'react-router-dom';

export default class Carousel extends React.Component {
  componentDidMount() {
    const $ = window.$;

    if ($('.slider-carousel').length) {
      $('.slider-carousel').owlCarousel({
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        loop: true,
        margin: 0,
        nav: false,
        singleItem: true,
        smartSpeed: 500,
        autoplay: true,
        autoplayTimeout: 6000,
        navText: [
          '<span class="icon-right-arrow"></span>',
          '<span class="icon-right-arrow"></span>',
        ],
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1024: {
            items: 1,
          },
        },
      });
    }
  }
  render() {
    let publicUrl = '/';
    return (
      <>
        <section className='slider-one-sec style3' style={{ marginTop: 0 }}>
          <div className='slider-carousel owl-carousel owl-theme'>
            {/* Start Slider One Single */}
            <div className='slider-one__single'>
              <div
                className='image-layer'
                style={{
                  backgroundImage:
                    'url(' +
                    publicUrl +
                    'assets/images/slider/cybersecurity-lider-2.jpg)',

                  // 'assets/images/slider/cybersecurity_slider.jpg)',
                }}
              ></div>
              <div className='container pl-0'>
                <div className='row clearfix'>
                  <div className='col-xl-12'>
                    <div className='slider-one__single-content text-center'>
                      <h3>Loi N°009/PR/2015 du 10 Février 2015</h3>
                      <h2>
                        CYBERSÉCURITÉ
                        <br />
                      </h2>
                      <p>
                        L'ANSICE est l’autorité nationale tchadienne chargée
                        d’assurer la <br /> cybersécurité des infrastructures
                        critiques de l’Etat .
                      </p>
                      <div className='btn-box'>
                        <Link
                          to={`/mission/2`}
                          className='thm-btn'
                          data-text='Savoir Plus +'
                        >
                          Savoir Plus +
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Slider One Single */}
            {/* Start Slider One Single */}
            <div className='slider-one__single'>
              <div
                className='image-layer'
                style={{
                  backgroundImage:
                    'url(' +
                    publicUrl +
                    'assets/images/slider/electonic-transation-slider-2.jpg)',
                }}
              ></div>
              <div className='container'>
                <div className='row clearfix'>
                  <div className='col-xl-12'>
                    <div className='slider-one__single-content text-white'>
                      <h3>Loi N°008/PR/2015 du 10 Février 2015</h3>
                      <h2>
                        SÉCURITÉ DES TRANSACTIONS ÉLECTRONIQUES <br />
                      </h2>
                      L’Agence Nationale de Sécurité Informatique et de
                      Certification Electronique (ANSICE) est l’autorité
                      nationale chargée d’assurer la sécurité des transactions
                      électroniques au Tchad. <br />
                      <div className='btn-box'>
                        <Link
                          to={`/mission/3`}
                          className='thm-btn'
                          data-text='Savoir Plus +'
                        >
                          Savoir Plus +
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Slider One Single */}

            {/* Start Slider One Single */}
            <div className='slider-one__single'>
              <div
                className='image-layer'
                style={{
                  backgroundImage:
                    'url(' +
                    publicUrl +
                    'assets/images/slider/data-protection-slider-3.jpg)',
                }}
              ></div>
              <div className='container'>
                <div className='row clearfix'>
                  <div className='col-xl-12'>
                    <div className='slider-one__single-content'>
                      <h3>Loi N° 007/PR/2015 du 10 février 2015</h3>.
                      <h2>
                        PROTECTION DES DONNÉES À <br />
                        CARACTÈRE PERSONNEL
                      </h2>
                      <p>
                        L’Agence Nationale de Sécurité Informatique et de
                        Certification Electronique (ANSICE) est l’autorité
                        nationale tchadienne chargée de la protection des
                        données personnelles. A ce titre, l’ANSICE est habilitée
                        à :
                      </p>
                      <div className='btn-box'>
                        <Link
                          to={`/mission/1`}
                          className='thm-btn'
                          data-text='Savoir Plus +'
                        >
                          Savoir Plus +
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Slider One Single */}
          </div>
        </section>
      </>
    );
  }
}
