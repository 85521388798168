import React from 'react';
import MainLayout from '../../layout/MainLayout';
import DirectionInfo from '../HomePage/DirectionInfo';

export default function DirecteurGeneralPage() {
  const publicUrl = '/';
  return (
    <MainLayout>
      <section className='mission-page'>
        <div className='p-5'>
          <div className='mb-5 sec-title text-center'>
            <div className='sec-title__tagline'>
              <span className='left'></span>
              <h6 className='mb-5'>Presentation</h6>{' '}
              <span className='right'></span>
            </div>
          </div>
          <DirectionInfo fullDetail={true} />
        </div>
      </section>
    </MainLayout>
  );
}
