import React, { useState } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import './OrgChartPageStyle.css';
import MainLayout from '../../layout/MainLayout';

// Organization Component to render each node
function Organization({ org, onCollapse, collapsed }) {
  return (
    <div className='org-node-container' onClick={onCollapse}>
      {org.image && (
        <div className='org-photo text-justify'>
          <img src={org.image} alt={org.name} />
        </div>
      )}
      {org.title && <div className='org-title'>{org.title}</div>}
      <div className='org-name'>{org.name}</div>
      {org.children && org.children.length > 0 && (
        <div className='expand-icon'>{collapsed ? '+' : '-'}</div>
      )}
    </div>
  );
}

// Node Component to manage tree structure
function Node({ o, parent }) {
  const [collapsed, setCollapsed] = useState(o.collapsed);
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const TreeComponent = parent ? TreeNode : Tree;

  return collapsed ? (
    <TreeComponent
      lineWidth={'2px'}
      lineColor={'#bbc'}
      lineBorderRadius={'12px'}
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    />
  ) : (
    <TreeComponent
      lineWidth={'2px'}
      lineColor={'#bbc'}
      lineBorderRadius={'12px'}
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    >
      {o.children &&
        o.children.map((child) => <Node key={child.id} o={child} parent={o} />)}
    </TreeComponent>
  );
}

// Main App Component
export default function OrgChartPage() {
  const orgData = {
    id: 2,
    name: 'PRÉSIDENT DU CONSEIL D’ADMINISTRATION',
    // image: 'assets/documents/photo/PCA.png',
    // title: 'Gal MAHAMAT TAHIR OROZI',
    children: [
      {
        id: 3,
        name: 'DIRECTION GÉNÉRALE',
        // image: 'assets/documents/photo/dg.jpeg',
        // title: 'ABDELKERIM ISSA OROZI BATIL (DG)',
        children: [
          {
            id: 4,
            name: 'CONSEILLÈRE TECHNIQUE',
            // image: 'assets/documents/photo/chidi.jpeg',
            // title: 'Mme. MAIDOWERE HAPSITA WAIDOU (CONSEILLERE)',
            children: [],
          },
          {
            id: 5,
            name: 'DIRECTIONS TECHNIQUES',

            children: [
              {
                id: 7,
                name: 'Direction des Affaires Juridiques (DAJ)',
                // children: [
                //   {
                //     id: 15,
                //     name: 'Directeur',
                //     title: 'OUSMAN TAHA',
                //     image: 'assets/documents/photo/daj.jpeg',
                //     children: [],
                //   },
                //   {
                //     id: 16,
                //     name: 'Adjoint',
                //     title: 'ABDOULHAMIR FADOUL',
                //     image: 'assets/documents/photo/daj.jpeg',
                //     children: [],
                //   },
                // ],
              },
              {
                id: 8,
                name: 'Direction du Contrôle de la Protection des Données Personnelles (DCPDP)',
                // children: [
                //   {
                //     id: 17,
                //     name: 'Directeur',
                //     title: 'YOUSSOUF CHIDI',
                //     image: 'assets/documents/photo/chidi.jpeg',
                //     children: [],
                //   },
                //   {
                //     id: 18,
                //     name: 'Adjoint',
                //     title: 'Me MATIAS',
                //     image: 'assets/documents/photo/chidi.jpeg',
                //     children: [],
                //   },
                // ],
              },

              {
                id: 9,
                name: "Direction de la Sécurité des Systèmes d'Information (DSSI)",
                // title: 'YOUSSOUF HASSAN KOREI (Directeur)',
                // image: 'assets/documents/photo/kore.jpeg', // Example photo URL
                // children: [
                //   {
                //     id: 19,
                //     name: 'Directeur',
                //     title: 'YOUNOUS HASSAN KOREI',
                //     image: 'assets/documents/photo/kore.jpeg', // Example photo URL
                //     children: [],
                //   },
                //   {
                //     id: 20,
                //     name: 'Adoint',
                //     title: 'IZEDINE GASSARI',
                //     image: 'assets/documents/photo/kore.jpeg', // Example photo URL
                //     children: [],
                //   },
                // ],
              },

              {
                id: 10,
                name: 'Direction de la Confiance Numérique et de la Veille Technologique (DCNVT)',
                // title: 'OUSMAN GOUKOUNI YOUSSOUF (Directeur)',
                // image: 'assets/documents/photo/ousman.jpeg', // Example photo URL
                // children: [
                //   {
                //     id: 21,
                //     name: 'Directeur',
                //     title: 'OUSMAN GOUKOUNI YOUSSOUF ',
                //     image: 'assets/documents/photo/ousman.jpeg', // Example photo URL
                //     children: [],
                //   },
                //   {
                //     id: 22,
                //     name: 'Adjointe',
                //     title: 'Mme ELDJOUMAN ROSE',
                //     image: 'assets/documents/photo/ousman.jpeg', // Example photo URL
                //     children: [],
                //   },
                // ],
              },
              {
                id: 11,
                name: 'Direction Commerciale et des Relations Extérieures (DCRE)',
                // title: 'Me HASSAN BOLOBO MAIDE (Directeur)',
                // image: 'assets/documents/photo/bolobo.jpeg', // Example photo URL
                // children: [
                //   {
                //     id: 23,
                //     name: 'Directeur',
                //     title: 'Me HASSAN BOLOBO MAIDE',
                //     image: 'assets/documents/photo/bolobo.jpeg', // Example photo URL
                //     children: [],
                //   },
                //   {
                //     id: 24,
                //     name: 'Adjointe',
                //     title: 'ZAHRA HISSEIN BRAHIM TAHA',
                //     image: 'assets/documents/photo/bolobo.jpeg', // Example photo URL
                //     children: [],
                //   },
                // ],
              },
              {
                id: 12,
                name: 'Direction des Affaires Administratives, Financières et du Matériel (DAAFM)',
                // title: 'AHMAT DOUGA BARTCHIRET (Directeur)',
                // image: 'assets/documents/photo/douga.jpeg', // Example photo URL
                // children: [
                //   {
                //     id: 25,
                //     name: 'Directeur',
                //     title: 'AHMAT DOUGA BARTCHIRET',
                //     image: 'assets/documents/photo/douga.jpeg', // Example photo URL
                //     children: [],
                //   },
                //   {
                //     id: 26,
                //     name: 'Adjoint',
                //     title: 'MAHAMT BICBARA BACHAR',
                //     image: 'assets/documents/photo/douga.jpeg', // Example photo URL
                //     children: [],
                //   },
                // ],
              },
              // Add more child nodes as needed
            ],
          },
          // {
          //   id: 13,
          //   name: 'DG',
          //   title: 'ABDELKERIM ISSA OROZI BATIL (DG)',
          //   image: 'assets/documents/photo/dg.jpeg',
          //   children: [],
          // },
          // {
          //   id: 14,
          //   name: 'DGA',
          //   title: 'Ndjma Saleh',
          //   image: 'assets/documents/photo/dg.jpeg',
          //   children: [],
          // },
        ],
      },
    ],
  };

  return (
    <MainLayout>
      <section className=''>
        <div className='p-5'>
          <div className='row'>
            <div className='col-md-10 sec-title text-center'>
              <div className='sec-title__tagline'>
                <span className='left'></span>
                <h6>Presentation</h6>
                <span className='right'></span>
              </div>
              <h2 className='sec-title__title'>Organigramme de l'ANSICE</h2>
            </div>

            <div className='col-md-12 text-center'>
              <a
                href='assets/documents/Organigramme-ANSICE.pdf'
                target='_blank'
                className='text-primary'
              >
                Télécharger en PDF <span className='icon-document'></span>
              </a>
            </div>
          </div>

          <div className='row mt-5 text-center orgchart-wra-pper'>
            <div className='orgchart-container'>
              <Node o={orgData} />
            </div>
          </div>
          <div className='row text-center orgchart-wrapper'>
            <div className='img-container'>
              <img
                className='mobile-chart img-fluid'
                src='assets/documents/orgchart.png'
                alt='Org Chart'
              />
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}
