import React, { useEffect, useState } from 'react';
import MainLayout from '../../layout/MainLayout';
import { useParams } from 'react-router-dom';
import MissionList from '../../data/missions.json';
import './styles.css';
import { removeExtraSpaces } from '../../Utility'; // Import the function

export default function MissionPage() {
  const { id } = useParams();
  const [missionData, setMissionData] = useState([]);

  useEffect(() => {
    setMissionData(MissionList.filter((item) => (id ? item.id == id : true)));
  }, [id]);

  let publicUrl = '/';
  return (
    <MainLayout>
      <section className='mission-page'>
        <div className='p-5'>
          <div className='sec-title text-center'>
            <div className='sec-title__tagline'>
              <span className='right'></span>
              <h2 className='sec-title__title'>Nos Missions</h2>

              <span className='right'></span>
            </div>
          </div>
          <section
            className='work-perfomance-one-sec'
            style={{ backgroundColor: '#fff' }}
          >
            <div className='container'>
              {missionData.map((item, index) => {
                const isEven = index % 2 === 0;
                return (
                  <div className='row mb-5' id={item.id} key={item.id}>
                    {!isEven && (
                      <div className='col-xl-4'>
                        <div className='work-perfomance-one__img'>
                          <img
                            src={publicUrl + item.imagePath}
                            alt={item.title}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className={`col-xl-8 ${isEven ? '' : 'order-xl-first'}`}
                    >
                      <div className='work-perfomance-one__content'>
                        <div className='sec-title_'>
                          <h2 className='sec-title__title'>{item.title}</h2>
                          <p className='sec-title__text'>
                            {removeExtraSpaces(item.description)}
                          </p>
                          <ul className='mt-3'>
                            {item.points.map((point, idx) => (
                              <li key={idx} className='bullet-point'>
                                {point}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {!!isEven && (
                      <div className='col-xl-4'>
                        <div className='work-perfomance-one__img'>
                          <img
                            src={publicUrl + item.imagePath}
                            alt={item.title}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </section>
    </MainLayout>
  );
}
